import React from 'react'
import logo from '../Assets/SAAF.svg'
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import { HiOutlinePhone } from "react-icons/hi";
import { IoLogoInstagram } from "react-icons/io";
import { BsTwitterX } from "react-icons/bs";
import { FiLinkedin } from "react-icons/fi";
import { IconContext } from 'react-icons';

const Footer = () => {
    const sociallinks = [
        {
            icon:<IoLocationOutline />,
            title:"6th Avenue, Yemoja street Oluyole Estate, Ibadan"
        },

        {
            icon:<HiOutlinePhone />,
            title:"+234 8056797872"
        },

        {
            icon:<MdOutlineEmail />,
            email:"hello@saaf_found.com",
        }
    
    ]
  return (
    <div className='bg-header p-10 mobile:flex-col mobile:h-[50%] mobile:p-5'>

        <div className='flex justify-between border-b border-[#e9e9e940] pb-5 mobile:flex-col'>
            <div className='flex w-[350px]'>
                <img src ={logo} alt="logo" className='h-[100px]' />
                <p className='text-[#E9E9E9] mx-2 text-tiny'>SAAF is dedicated to cultivating peace, prioritize growth and unite critical minds for a prosperous tomorrow. </p>
            </div>

            <div className='text-white flex  flex-col w-[30%] mobile:w-full mobile:my-5'>
                {sociallinks.map((item, index) =>{
                    return(
                        <div className='flex items-center mb-3' key={index}>
                        <IconContext.Provider value={{size:"25px"}}>
                            {item.icon}
                            <p className='text-tiny mx-2'>{item.title}</p>
                            <a href={`mailto:${item.email}`} className='text-tiny'>{item.email}</a>
                        </IconContext.Provider>
                        
                        </div>
                    )
                })}
            </div>

            <div className='flex items-center flex-col w-[10%] mobile:flex-row mobile:border-white border mobile:w-[50%]'>
                <p className='text-white my-2'>Follow us</p>
                <div className='flex items-center justify-between text-white w-full '>
                    <div className='border border-white rounded-full p-2'>
                        <FiLinkedin />
                    </div>

                    <div className='border border-white rounded-full p-2'>
                        <BsTwitterX />
                    </div>

                    <div className='border border-white rounded-full p-2'>
                        <IoLogoInstagram />
                    </div>


                </div>
            </div>
        </div>

        <div className='flex justify-between items-center text-[#e9e9e940] py-2'>
            <p>&copy; Copyright 2024 SAAF</p>
            <p>Developed by Fireswitch Technologies</p>
        </div>
      
    </div>
  )
}

export default Footer
