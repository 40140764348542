import React from 'react'

const About = () => {
  return (
    <div className='p-20 mobile:px-5 mobile:p-5 Tablet:p-10'>
        <div className=''>
            <h1 className='text-header font-bold text-small'>Our Mission Statement</h1>
            <div className='bg-gradient1 h-2 w-[15rem] rounded-lg m-1'></div>
        </div>

        <div className='my-10'>
            <p className='leading-10 '>
            The mission statement of the Senator Abiola Ajimobi Foundation (SAAF) is to build a secure, progressive Nigeria by enhancing the quality of education across all educational institutions and instilling values that promote peace among Nigerians. SAAF aims to promote the gathering of critical minds to harness diverse thoughts and perspectives in achieving national goals. The ultimate goal is to create a peaceful society and contribute to the development of Nigeria.
            </p>

            <p className='leading-10 my-5'>The Senator Abiola Ajimobi Foundation (SAAF) has several initiatives to achieve its goals. Here are a few of them:
            </p>

            <ol className='list-decimal leading-10 my-5'>
                <li>Peace Education Programs: SAAF conducts peace education programs in schools and communities to promote peaceful coexistence and conflict resolution skills among Nigerians.</li>

                <li> Grants for Educational Institutions: SAAF provides grants to educational institutions in Nigeria to support the improvement of infrastructure, learning resources, and overall educational quality.</li>

                <li>Advocacy Against Violence: SAAF actively advocates against cultural, structural, and social violence, raising awareness and promoting peaceful values and ideals.</li>

                <li> Scholarships and Educational Support: SAAF offers scholarships and educational support to deserving students, enabling them to access quality education and pursue their dreams.</li>

                <p className='my-5'>These initiatives highlight SAAF's commitment to fostering peace, improving education, and creating positive change in Nigeria.</p>

                <p className='my-10 leading-10'>SAAF also organizes the annual Abiola Ajimobi RoundTable, where important issues of national discourse take the spotlight. It's a platform for critical discussions and gathering of minds to address pressing matters and contribute to the development of Nigeria. This RoundTable serves as a catalyst for meaningful conversations and the exchange of ideas. It's a fantastic initiative to engage in constructive dialogue for the betterment of our nation.</p>

            </ol>
        </div>


    </div>
  )
}

export default About
