import React from 'react'
import { NewsData } from '../Components/NewsData'
import { Link } from 'react-router-dom'

const News = () => {
 
  return (
    <div className='p-20 mobile:p-5 smtab:p-10 Tablet:p-10'>
        <div className=''>
            <h1 className='text-header font-bold text-small'>News</h1>
            <div className='bg-gradient1 h-2 w-[4rem] rounded-lg my-1'></div>
        </div>

        <div className='my-10'>
            {NewsData.map((item ) =>{
               return( 
                    <Link key={item.id} to ={`/News/${item.id}`} className='bg-[#E2F5FD] rounded-xl flex mb-7 mobile:flex-col ' >
                        <div className='h-[280px] w-[100%] relative overflow-hidden rounded-xl smtab:h-[280px] smtab:w-[80%]'>
                            <img src={item.image} className='absolute h-[280px] w-[100%] object-cover smtab:h-[280px] smtab:w-[100%] smtab: ' alt="newsimage"/>
                        </div>
                        <div className='flex flex-col justify-between w-[100%] py-4 mobile:px-2 smtab:py-2 smtab:px-2'>
                            <h1 className='text-semiSmall font-bold  smtab:text-regular px-4'>{item.title}</h1>

                            <div className='px-4  '>
                                <p className='text-regular text-[#001C54] leading-10 smtab:text-default'>{item.date}</p>
                                <p className='text-default smtab:text-tiny'>{item.subtext}</p>
                            </div>
                        </div>
                    </Link>
            )
            })}
        </div>
    </div>
  )
}

export default News
