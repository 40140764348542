import React from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { NewsData } from './NewsData'
// icons
import { IoLogoInstagram } from "react-icons/io";
import { BsTwitterX } from "react-icons/bs";
import { FiLinkedin } from "react-icons/fi";
import { IconContext } from 'react-icons';
import Newscomponent from './Homepage/Newscomponent';


const NewsStory = () => {
    const {id} = useParams()
    const navigate = useNavigate()


    const story = NewsData.find((news) => news.id === id)
    const {title, date,  mainStory, image } = story

// icons array
    const socials = [
        { 
         logo:<IoLogoInstagram />,
         link:"instagram.com"
        },
 
        { 
         logo: <BsTwitterX />,
         link:"X.com"
        },
 
        { 
         logo:<FiLinkedin />,
         link:"linkedIn.com"
        },
 
     ]
 
  return (
    <div className='p-20 mobile:p-5 smtab:p-10 Tablet:p-10  '>
        <div className='flex items-center my-10 mobile:my-2' onClick={() => navigate(-1)}> 
        <p>Back</p>
        </div>

    
        <div className='px-5 py-7 bg-[#E2F5FD]'>

            <h1 className='text-small font-bold'>{title}</h1>

            <div className='mt-14'>
                <div className='flex justify-between items-center'>
                    <p>{date}</p>
                    
                    <div className='flex items-center text-[#001C54]'>
                        <p className='mr-10'>Share via</p>
                        <div className='flex items-center'>
                        <IconContext.Provider value={{size:"15px"}}>
                            {socials.map((item, index) =>{
        
                            return (
                                <div key = {index} className='border-2 border-[#001C54] rounded-full p-1 mx-1'>
                                <Link>{item.logo}</Link>
                                </div>
                            )
                            })}
                            </IconContext.Provider>
                        </div>
                    </div>
                </div>

                <div>
                    {mainStory.map((items, index) =>{
                        return <p className='leading-10 my-4' key={index}>{items}</p>
                    })}
                </div>
            </div>
            
        </div>

        <div className='w-full my-10'>
                    <img src={image} alt="images" className='object-fill h-full w-full' />
        </div>

        <div>
            <Newscomponent />
        </div>
    
      
    </div>
  )
}

export default NewsStory
