import React, { useState} from 'react'
import { Outlet, NavLink, Link, useLocation } from 'react-router-dom'
import saaf from "../Assets/SAAF.svg"
import Buttons from "../Resources/Buttons"
import { IoMdInformationCircle } from "react-icons/io";
import { IoLogoInstagram } from "react-icons/io";
import { BsTwitterX } from "react-icons/bs";
import { FiLinkedin } from "react-icons/fi";
import { IconContext } from 'react-icons';
import { CiMenuBurger } from "react-icons/ci";
import { IoClose } from 'react-icons/io5';
import Footer from '../Resources/Footer';

const BaseLayout = () => {
    // const navigate = useNavigate()
    const location = useLocation()

    const [showNav, setShowNav] = useState(false)
    const [width, setWidth] =useState(window.innerWidth)

    const handleWidth = () =>{
        setWidth(window.innerWidth)
        if(width >= 1024){
            setShowNav(false)
        }
     } 
     window.addEventListener('resize', handleWidth)

    const navData = [
        {       
            title: "Home",
            path:"/"
        },

        {
            title: "About Us",
            path:"/About"
        },


        {
            title: "News",
            path:"/News"
        },


        {
            title: "Media Gallery",
            path:"/Media"
        },

        {
            title: "Contact Us ",
            path:"/contact"
        },

    ]

    const socials = [
       { 
        logo:<IoLogoInstagram />,
        link:"instagram.com"
       },

       { 
        logo: <BsTwitterX />,
        link:"X.com"
       },

       { 
        logo:<FiLinkedin />,
        link:"linkedIn.com"
       },

    ]

  return (
    <div className='font-arvo w-full flex relative  h-[100vh] overflow-hidden'>
  {/**OVERLAY */}
    <div className={showNav ? 'absolute top-0 bottom-0 left-0 right-0 bg-black w-full z-[100000] opacity-[.5] flex justify-end': "hidden"} onClick={() => setShowNav(false)}> 
        <IconContext.Provider value={{size:"50px", color:"white"}} >
                <IoClose  onClick={() => setShowNav(false)}/>
        </IconContext.Provider>
    </div>
  {/**side navigation */}
      <div className={showNav ? ' flex flex-col items-center shadow-navbar w-[15%] smtab:w-[30%] h-[100vh] mobile:block absolute z-[1000000000] bg-white mobile:w-[50%] ' : 'flex flex-col shadow-navbar w-[15%] h-[99vh] mobile:hidden smtab:hidden items-center  Tablet:w-[20%]'}>


    {/**logo */}
        <div className='py-5 px-2'>
            <img src={saaf} alt="logo" className='h-auto mobile:h-[100%]'/>
        </div>
    
    {/**navigation */}
        <div className='flex flex-col justify-between border-t-2 mx-2 my-5  h-[50%]'>
            <ul className='flex flex-col justify-between my-2'>
                {navData.map((item, index) =>{
                return(
                    <li key={index} className={location.pathname === item.path ? ' border-b-2    rounded-md text-center py-2 my-4 mx-4 hover:border-b-2 hover:border-navbd transition-all border-navbd':' border-b-2 border-white rounded-md text-center py-2 my-4 mx-4 hover:border-b-2 hover:border-navbd transition-all active:border-navbd'} onClick={() => setShowNav(false)}>
                        <NavLink to={item.path}>{item.title}</NavLink>
                    </li>
                )
                })}
            </ul>

            <div className='flex items-center justify-center h-auto  '>
                {/**donate button */}
                <Buttons title="Apply for SAAF grant" btnStyle=" text-tiny px-5 py-3 border  border-black cursor-pointer rounded-md hover:bg-header hover:text-white transition-all " action={
                    () => window.open('https://docs.google.com/forms/u/1/d/e/1FAIpQLSetEvhz2vEiKqSqZRVurf4bq8iKIpFAAZEkl2GoMIdueXxEFw/viewform?usp=send_form ', '_blank', 'noopener')
                }/>
            </div>
        </div>
    
      </div>

    {/**web content */}      
      <div className='w-full h-[100vh]'>
       
        <div className='bg-header px-20 py-7 min-w-[90%] fixed flex items-center justify-between z-[1] mobile:px-3 mobile:flex-col mobile:items-start mobile:h-[20%] mobile:w-full mobile:py-5 mobile:relative smtab:p-4 Tablet:px-5 smtab:w-full'>
            <div className='hidden mobile:block smtab:block ' onClick={() => setShowNav(true)}>
            <IconContext.Provider value ={{color:"white", size:"30px"}}>
             <CiMenuBurger />
            </IconContext.Provider>
            </div>
        {/**infosection of the header */}
            <div className='flex items-center text-notify'>
                <IconContext.Provider value={{size:"25px", color:"#FFA500"}}>
                    <IoMdInformationCircle />
                    <Link 
                    to='https://docs.google.com/forms/u/1/d/e/1FAIpQLSetEvhz2vEiKqSqZRVurf4bq8iKIpFAAZEkl2GoMIdueXxEFw/viewform?usp=send_form ' target='_blank' 
                    className='italic mx-2 text-tiny'>Apply for SAAF (Sen. Abiola Ajimobi Foundation) grant</Link>
                 </IconContext.Provider>
            </div>

            <div className='flex items-center text-white'>
                <p>Contact Us</p>
                <div className='flex items-center'>
                <IconContext.Provider value={{size:"15px"}}>
                    {socials.map((item, index) =>{

                       return (
                        <div key = {index} className='border border-white rounded-full p-1 mx-2'>
                         <Link>{item.logo}</Link>
                        </div>
                    )
                    })}
                    </IconContext.Provider>
                </div>
                
            </div>
        </div>

         {/**body Navigation */}
         <div className=' h-full overflow-y-scroll'>
             <Outlet />
             <Footer />
         </div>

      </div>

    </div>
  )
}

export default BaseLayout
