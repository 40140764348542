import React from 'react'
import Field from '../Resources/Field'
import { useData } from '../Context/context'
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import { HiOutlinePhone } from "react-icons/hi";
import { IoLogoInstagram } from "react-icons/io";
import { BsTwitterX } from "react-icons/bs";
import { FiLinkedin } from "react-icons/fi";
import Buttons from '../Resources/Buttons';




const Contact = () => {
    const sociaLContact = [
        {
            icon:<IoLocationOutline />,
            title:"6th Avenue, Yemoja street Oluyole Estate, Ibadan"
        },

        {
            icon:<MdOutlineEmail />,
            title:"hello@saaf_found.com",
            email:"hello@saaf_found.com"
        },

        {
            icon:<HiOutlinePhone />,
            title:"+234 8056797872"
        },

        {
            icon:<IoLogoInstagram />,
            title:"SAAF Foundation"
        },

        {
            icon:<BsTwitterX />,
            title:"SAAF Foundation"
        },

        {
            icon:<FiLinkedin />,
            title:"SAAF Foundation"
        }

    ]

    const {contact, handleContact, submitContact} = useData()
  return (
    <div className='h-[100vh] flex items-center justify-center'>
        <div className='  w-[60%] h-[70%] p-10 shadow-default flex items-center justify-end relative mobile:p-2 smtab:w-[80%] mobile:w-[90%] Tablet:w-[80%]' >
        {/**the input fields */}
            <div className='w-[70%] mobile:w-full Tablet:w-[60%]'> 

            {/**heading */}
            <div className='py-5'>
                <h1 className='text-header font-bold text-small'>Get In Touch</h1>
                <div className='bg-gradient1 h-3 w-[6rem] rounded-lg my-1'></div>
            </div>

            <form className='w-[100%] '>
                <Field 
                    type="text"
                    placeholder="Your Name"
                    inputname="name"
                    inputValue={contact['name']}
                    change={handleContact}
                />

                <Field 
                    type="email"
                    placeholder="Your Email"
                    inputname="email"
                    inputValue={contact['email']}
                    change={handleContact}
                />

                <textarea 
                    placeholder='Type your message here '
                    name="message"
                    value ={contact['message']}
                    onChange={handleContact}
                    className='w-full my-3 border border-[#00000033] h-[200px] p-3 rounded-md'

                />

                <div className='flex justify-end'>
                    <Buttons title="Send" btnStyle="py-3 px-10 border bg-header rounded-lg text-white" action={submitContact}/>
                </div>

                

            </form>
            </div>

            <div className='bg-header absolute  top-[5rem] left-[-150px] w-[45%] h-auto px-7 py-10 mobile:hidden smtab:p-5 smtab:w-[40%] smtab:left-[-70px] smtab:top-[3rem] Tablet:top-[2rem] Tablet:left-[-70px]'>
                <p className='text-white font-bold text-small'>Contact Us</p>

                <div className='flex flex-col  h-[90%] py-2 my-5'>
                    {sociaLContact.map((item, index ) =>{
                        return(
                            <div key={index} className='text-white flex items-center mb-5 smtab:text-tiny'>
                                <div className='border-2 border-white rounded-full p-2'>
                                    {item.icon}
                                </div>
                                <p className='ml-5 text-tiny Tablet:text-micro'>{item.title}</p>
                            </div>
                        )
                    })}

                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact
