import img2 from "../Assets/gallery/img2.jpeg"
import img6 from "../Assets/gallery/img6.jpeg"

export const NewsData = [
    {
        id:"1",
        image:img2,
        title:"Osinbajo, others present as Ajimobi foundation dedicates building to varsity",
        date:"December 17, 2021.",
        subtext:"The Sen. Abiola Ajimobi Foundation (SAAF) on Thursday in Ibadan dedicated a resource centre to the Institute for Peace and Strategic Studies (IPSS), University of Ibadan.",
        mainStory: [
           " A statement by SAAF President Florence Ajimobi enjoined Nigerians to continue to live in harmony as this is the only way peace could be established in the country.",

             'Ajimobi expressed concern about the prolonged conflict between Russia and Ukraine, pleading with world leaders to act in order to stem the human and material carnage due to the conflict.', 

             "She said: “As of last month, the war in Ukraine witnessed over six thousand civilian deaths while  the number of refugees has risen to over 6.6 million.”", 

             "She also noted the economic implication of the war with the destruction costing Ukraine as much as $113.5billion while about $200 billion would be needed to rebuild, money that could have been used for other developmental purposes.",
        ]
    },

    {
        id:"2",
        image:img6,
        title:"Int’l Day of Peace: Ajimobi Foundation calls for peaceful co-existence",
        date:"September 21, 2022.",
        subtext:"As the world mark’s the international day of peace today, the Senator Abiola Ajimobi Foundation (SAAF) has called for peaceful coexistence among countries of the world.",
        mainStory: [
            "NAN reports that the dedication was performed during the 4th Sen. Abiola Ajimobi Roundtable and 72nd Posthumous birthday held at the University of Ibadan (UI). Mrs Florence Ajimobi, wife of the late former governor and SAAF President, said the project was in furtherance of the promotion of late Ajimobi’s legacies on good governance. She said that the project was aimed at promoting the legacies which the collaboration of SAAF and IPSS was vigorously pursuing.",

             '“This centre, we expect, will further advance the quest for knowledge as it is serving even from today. “The roundtable has, content-wise, espoused ‘Biola’s desire to continuously challenge public governance and its executors towards enhancing the quality of governance and leadership in our country,” she said. Mrs Ajimobi said that the ultimate desire was to attain a progressively advancing country towards realising its essence which is basically to advance the course of humanity. She said that theme of the roundtable, entitled, ‘Election Security and Good Governance’, was intended to prepare the grounds for peaceful as well as proper elections in Nigeria.', 

             "The SAAF president said that the country would emerge stronger and compellingly more purposeful for its citizenry when the process of assuming leadership was further strengthened. Ajimobi expressed hope that positive development would be attained at subsequent polling exercises.  Prof. Kayode Adebowale, Vice-Chancellor, University of Ibadan, appreciated the Ajimobi family and SAAF for the donation, promising proper utilisation of the edifice.  Adebowale, who was represented by Prof. Ezekiel Ayoola, Deputy Vice-Chancellor (Administration), said that the late Ajimobi was a student of the institute until his untimely death..", 

             "Dr Dominic Danjibo, IPSS Director, said that Nigerians don’t prioritise nor invest in education as expected, saying universities were lowly-funded in the country. Danjibo, who appreciated the newly dedicated building, promised that the structure would continue to produce great minds in the country. Prof. Attahiru Jega, the Lead Speaker, said that security challenges undermine electoral integrity, credibility and prevent citizens from participating freely in electoral processes. Jega urged Nigerians to give their best in the building of an egalitarian society where Nigerians would live peacefully and choose their leaders through credible elections. Retired Brig.-Gen.Sani Usman, Mr Babajide Otitoju, Mr Majeed Dahiru and Prof. Antonio Simbine, who were discussants at event, also stressed the need to address   the challenges of insecurity in the country.  They all submitted that the insecurity ravaging the country must be defeated for Nigerians to live in harmony and create a conducive environment for good governance.",

             "Earlier, Prof. Isaac Albert, the moderator of the roundtable, said the essence of the programme was to bring the people together to brainstorm on how security challenges during elections could be addressed.He said that the platform availed stakeholders with various experiences and expertise to chart a way forward for free, fair and credible elections in future.NAN reports that the event was attended by Alhaji Rauf Olaniyan, Oyo State Deputy Governor and his Osun State counterpart, Mr Benedict Alabi. Also present were Sen. ",
             
             "Abdulfatai Buhari (APC-Oyo North), Mr Sunday Dare, the Minister of Sports, Rep. Shina Peller, Rep. Tolulope Akande-Sadipe, Chief Adebayo Adelabu and Sen. Ayoade Adeseun. Others were Prof. Adeniyi Olowofela, members of the Ajimobi family, traditional rulers and hosts of prominent political leaders. NAN reports that Sen. Bola Tinubu, Chief Bisi Akande, Ooni of Ife, Oba Enitan Ogunwusi were all represented at the event. (NAN).."
            
            ]
    },

    

  ]