import './App.css';
import { createBrowserRouter,
         createRoutesFromElements,
         Route,
         RouterProvider
 } from 'react-router-dom'; 
import BaseLayout from './Pages/BaseLayout';
import { DataProvider } from './Context/context';
import Home from "./Pages/Home"
import About from './Pages/About';
// import Programs from './Pages/Programs';
import News from './Pages/News';
import NewsStory from './Components/NewsStory';
import Gallery from './Pages/Gallery';
import Contact from './Pages/Contact';
// import Donate from './Pages/Donate';

 const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<BaseLayout />}>
      <Route path="/" element = {<Home/>} exact />
      <Route path='/About'  element = {<About />} />
      {/**<Route path='/Programs'  element = {<Programs />} />
 */}
      <Route path="/News" element={<News />} />
      <Route path="/News/:id" element={<NewsStory />} />
      <Route path="/Media" element={<Gallery />} />
      <Route path="/Contact" element={<Contact />} />
      {/**<Route path="/Donate" element ={<Donate />} /> */} 
    </Route>
  )
 )

function App() {
  return (
    <DataProvider>
        <RouterProvider router = {router} />
    </DataProvider>
  );
}

export default App;
