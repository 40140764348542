import React from 'react'

const Field = ({type, placeholder, inputname, inputValue, change }) => {
  return (
    <div className='w-full'>
        <input 
            type={type}
            placeholder={placeholder}
            name={inputname}
            value={inputValue}
            onChange ={change}
            className="my-3 p-3 border border-[#00000033] outline-none w-[100%] rounded-md"
        />
    </div>
  )
}

export default Field
