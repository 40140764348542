import React from 'react'
import Buttons from '../Resources/Buttons'
import {useNavigate} from "react-router-dom"
import banner from '../Assets/banner.png'
import banner2 from  '../Assets/banner2.png'
import vect from '../Assets/Vector.svg'
import logo from '../Assets/SAAF.svg'
import Carousels from '../Components/Homepage/Carousel'
import Newscomponent from '../Components/Homepage/Newscomponent'
const Home = () => {
    const navigate = useNavigate()
  return (
    <div className='w-full font-arvo pt-20 mobile:py-5'>
        {/**Banner */}
        <div className='flex items-center justify-between p-20 mobile:p-5 smtab:p-10 smtab:items-start Tablet:p-10 Tablet:items-start'>
            <div className='mobile:p-'>

                <div className=''>
                    <h1 className='text-large font-bold bg-gradient-to-r from-gradient1 via-gradient2  to-gradient2 text-transparent bg-clip-text mobile:text-small mobile:mb-5 w-full smtab:text-medium Tablet:text-medium'>Peace, Growth  and Development</h1>
                    <p className='text-textcl'>Join us in our mission to cultivate peace, prioritize<br/> growth and unite critical minds for a prosperous <br/>tomorrow.</p>
                </div>
            <div className='my-10'>
                <Buttons title="Apply for SAAF grant" btnStyle=" px-16 py-4 border  border-black cursor-pointer  mobile:py-2 mobile:px-10 rounded-md hover:bg-header hover:text-white transition-all " action={
                    () => window.open('https://docs.google.com/forms/u/1/d/e/1FAIpQLSetEvhz2vEiKqSqZRVurf4bq8iKIpFAAZEkl2GoMIdueXxEFw/viewform?usp=send_form ', '_blank', 'noopener')} />
            </div>
            </div>

            <div className='mobile:hidden'>
                <img src={banner} alt="banner"  />
            </div>
        </div>
        
        <div className='bg-no-repeat border-t py-10 px-20  flex items-center justify-between relative mobile:flex-col-reverse mobile:p-2 smtab:flex-col-reverse '>
        <img src={banner2} className='absolute z-0 mobile:h-[50%]' alt="bkg"/>
           <div className='py-10 flex flex-col smtab:w-full'>
                <div className='flex items-center p-2 shadow-default bg-white rounded-lg relative self-start'>
                    <div className=' h-7 w-7 bg-[#DF12B2]'></div>
                    <p className='m-2'>Peace Education Programs</p>
                </div>

                <div className='flex items-center content-left ml-10 my-10 p-2 shadow-default bg-white rounded-lg relative self-end' >
                    <div className=' h-7 w-7 bg-[#086BA4]'></div>
                    <p className='m-2'>Grants for Educational Institutions</p>
                </div>

                
                <div className='flex items-center p-2 shadow-default bg-white rounded-lg relative self-start' >
                    <div className=' h-7 w-7 bg-[#048860]'></div>
                    <p className='m-2'>Advocacy Against Violence</p>
                </div>

                <div className='flex items-center content-left ml-10 my-10 p-2 shadow-default bg-white rounded-lg relative self-end' >
                <div className=' h-7 w-7 bg-[#C30606]'></div>
                <p className='m-2'>Grants for Educational Institutions</p>
            </div>
           </div>

           <div className='w-[50%] mobile:w-full mobile:p-2 smtab:w-[100%]  Tablet:ml-5'>
                <div>
                    <h1 className='text-header font-bold text-small mobile:text-regular '>What We Do?</h1>
                    <div className='bg-gradient1 h-2 w-[8rem] rounded-lg m-1 mobile:w-[7rem]'></div>
                </div>

                <div className='text-textcl my-7 leading-10'>
                    <p>The Senator Abiola Ajimobi Foundation (SAAF) has several initiatives to achieve its goals. Here are a few of them:</p>

                    <ul className='list-disc m-4 leading-10'>
                        <li>Peace Education Programs</li>
                        <li>Grants for Educational Institutions</li>
                        <li>Advocacy Against Violence</li>
                        <li>Scholarships and Educational Support </li>

                    </ul>
                    <Buttons action={() => navigate('/About') } btnStyle=" text-black py-2 px-10 border border-black rounded-md " title="learn more"/>
                    
                </div>
           
           </div>
        </div>

        <div className='bg-[#E7F6FD] relative py-10 px-[10rem] mobile:px-2 smtab:p-5'>
                 <div>
                    <img src = {vect} alt="vector" className='absolute top-0 left-[120px] z-0 mobile:left-0'/>
                 </div>   

                 <div className='flex z-[10000] items-center justify-between'>
                    <p className='text-header text-semiSmall z-[100] w-[70%] font-[500] mobile:text-default'>For 10 Years, SAAF has built a secure, progressive Nigeria by enhancing the quality of education across all educational institutions and instilling values that promote peace among Nigerians.
                    </p>

                    <img src={logo} alt ="logo" className='mobile:z-[1000]'/>
                 </div>
        </div>

        <div >
            <div className='p-20 mobile:p-5 smtab:p-5 Tablet:p-5'>
                <h1 className='text-header font-bold text-small'>Media Gallery</h1>
                <div className='bg-gradient1 h-2 w-[4rem] rounded-lg my-1'></div>
            </div>
            <Carousels />
        </div>

        <div>
            <div className='p-20 mobile:p-5 smtab:p-5 Tablet:p-5'>
                <h1 className='text-header font-bold text-small'>News </h1>
                <div className='bg-gradient1 h-2 w-[4rem] rounded-lg my-1'></div>
            </div>

            <Newscomponent />
            <div className='flex items-center justify-center'>
            <Buttons action={() => navigate('/News')} title="View More" btnStyle="py-3 px-10 border border-black my-5 rounded-md"/>
          </div>
        </div>
    </div>
  )
}

export default Home
