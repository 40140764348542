import React, {useEffect, useState} from 'react'
import { SlClose } from "react-icons/sl";
import { IconContext } from 'react-icons'
import { MediaData } from '../Components/ImageData';

const Gallery = () => {

    const [active, setActive] = useState("")
    const [show, setShow] = useState(false)

    useEffect(() =>{
        if(active){
            setShow(true)
        }
        // eslint-disable-next-line 
    }, [active])
  return (
    <div className='p-20 relative mobile:p-2 smtab:p-5 Tablet:p-10'>
    <div className=''>
        <h1 className='text-header font-bold text-small'>Media Gallery</h1>
        <div className='bg-gradient1 h-2 w-[4rem] rounded-lg my-1'></div>
    </div>
    
    <div>
        <div className='grid grid-cols-3 gap-6 my-10 mobile:grid-cols-2 mobile:gap-y-10'>
         {MediaData.map((item, index) =>{
          return ( 
            <div className=' h-[250px] w-[350px] rounded-xl mobile:h-[150px] mobile:w-[100%]  smtab:h-[150px] smtab:w-[100%]  Tablet:h-[150px] Tablet:w-[100%]' key={index}>
                <img src={item} className='h-[250px] w-[350px] object-cover rounded-xl mobile:h-[100%] mobile:w-[100%] smtab:h-[150px] smtab:w-[190px]  Tablet:h-[150px] Tablet :w-[100%]' onClick={(e) => setActive(item) } alt="gallery"/>
            </div>
        )
         })}

        </div>
    </div>
     {show ? 
        <div className='fixed top-0 left-0 right-0 h-[100%] w-[100%] r border-3 ' >
            <IconContext.Provider value ={{size:"30px", color:"white"}}>
                <div className='absolute top-32 z-[10000000] right-5 cursor-pointer mobile:top-[60%] mobile:left-[45%]' onClick={() => setShow(false)}>
                    <SlClose />
                </div>
            </IconContext.Provider>
            <div className='absolute bg-black opacity-[.7] top-0 w-full  h-full' onClick={() => setShow(false)}></div>
            <div className='h-[50%] w-[50%] translate-x-[50%] translate-y-[50%] mobile:h-auto mobile:translate-x-[0%] mobile:w-full mobile:translate-y-[1000px]  smtab:translate-x-[0%] smtab:w-full smtab:translate-y-[60%] Tablet:translate-x-[0%] Tablet:w-full Tablet:translate-y-[50%]'>
              <img src={active} alt='images ' className='object-fill h-[100%] w-[100%] mob' />
            </div>
        </div>
        : null

     }    
   


    </div>
  )
}

export default Gallery ;
