import React from 'react'

const Buttons = ({action, title, btnStyle}) => {
  return (
    <div>
        <button onClick ={action} className={btnStyle}>{title}</button>
    </div>
  )
}

export default Buttons
