import React from 'react'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react' 
import { MediaData } from '../ImageData'
import '../../App.css'


const animation = { duration: 20000, easing: (t) => t }

const  Carousels = ( ) =>{

    const [sliderRef] = useKeenSlider({
        loop: true,
        renderMode: "performance",
        drag: true,
        created(s) {
          s.moveToIdx(5, true, animation)
        },
        updated(s) {
          s.moveToIdx(s.track.details.abs + 5, true, animation)
        },
        animationEnded(s) {
          s.moveToIdx(s.track.details.abs + 5, true, animation)
        },
      })
     

    return(
        <div className='w-[100%]  overflow-hidden relative flex items-center justify-center Tablet:p-5 Tablet:justify-start tablet'>
        <div className='keen-slider ' ref={sliderRef} >
            {MediaData.map((item, index) =>{
                return(
                    <div key={index} className='keen-slider__slide '>
                        <img src={item} alt={index} className='h-full w-full object-cover mx-5'/>
                    </div>
                )
            })}
        </div>

        </div>
        
    )
}

export default Carousels