import { createContext, useContext, useState } from "react";


//context instance
export const dataContext = createContext()

// custon hook for the context
export const useData = () =>{
    return useContext(dataContext)
}

export const DataProvider = ({children}) => {
    //contact Us page state mnagement
    const [contact, setContact] = useState({
        name:"",
        email:"",
        message:""
    })

    const [donation, setDonation] = useState({
        name:"",
        mail:"",
        donating:"",
        preference:"",
        note:""

    })
//onchange function for the contact us page
    const handleContact = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setContact({...contact, [name]: value})
        
    }
//contact us submit function
    const submitContact = (e) =>{
        e.preventDefault()
        
    }


    //Donate onchange function
    const handleDonate = (e) =>{
        let name = e.target.name
        let value = e.target.value

        setDonation({...donation, [name]: value })
       
    }
    return(
        <dataContext.Provider value ={{contact, setContact,handleContact, submitContact, donation, setDonation, handleDonate}}>
            {children}
        </dataContext.Provider>
    )
}

