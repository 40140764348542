import React from 'react'
import {Link} from 'react-router-dom'
import { NewsData } from '../NewsData'

const Newscomponent = () => {

  return (
    <div className='px-20 Tablet:px-5  mobile:px-5 smtab:p-5 w-full'>
      <div className='flex justify-between mobile:flex-col smtab:flex-col smtab:items-center w-full'>
        {NewsData.map((item, index) =>{
           return(
            <Link key={index} className='w-[350px] shadow-default rounded-md mobile:w-full mobile:my-5 smtab:w-[60%] Tablet:w-[250px] smtab:my-5 flex-wrap' to ={`/News/${item.id}`}>
                <div className='h-[250px]'>
                    <img src ={item.image} alt={index} className='object-fill h-full w-full' />
                </div>

                <div className='mx-1 p-3'>
                    <p className='font-[600] my-2'>{item.title}</p>
                    <p className='my-2'>{item.date}</p>
                    <p className='text-tiny'>{item.subtext}</p>
                </div>
            </Link>
           )
        })}
      </div> 
    </div>
  )
}

export default Newscomponent
